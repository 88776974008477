import swaggerClient from "../plugins/swagger-client";

export default class SubscriptionService {
	retrieveActiveSubscriptionByClientId(clientId) {
		return swaggerClient.swagger
			.then((client) => {
				return client.apis.subscriptions.retrieveActiveSubscriptionByClientId(
					{ clientId },
					swaggerClient.buildRequestBody(),
					{}
				);
			})
			.then((response) => {
				return response.body;
			})
			.catch((error) => {
				return Promise.reject(error);
			})
	}
}