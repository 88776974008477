import services from "../services";
// import router from "../router/index";

// const AUTHORIZATION_ERROR_STATUS = 401;
const strict = true;

const getDefaultState = () => {
    return {
      activeSubscription:null
    };
};

const state = getDefaultState();

const getters = {
    getActiveSubscription: state => state.activeSubscription
}

const mutations = {
    RESET(state) {
      const s = getDefaultState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    SET_ACTIVE_SUBSCRIPTION(state,subscription){
      state.activeSubscription = subscription
    }
};

const actions = {
    retrieveActiveSubscriptionByClientId({commit,rootGetters}){
      const clientId = rootGetters["auth/getClientId"];
      return services.SubscriptionService.retrieveActiveSubscriptionByClientId(clientId)
        .then((response)=>{
          return response;
        })
        .then((subscription)=>{
          commit("SET_ACTIVE_SUBSCRIPTION",subscription)
        })
        .catch((error) => {
          commit("SET_ACTIVE_SUBSCRIPTION", null);
          return Promise.reject(error);
        });
    }
}


// utils
// function checkRedirectionByStatus(status, commit) {
//   if (status === AUTHORIZATION_ERROR_STATUS) {
//     commit("auth/SET_IS_LOGGED_IN", false, { root: true });
//     router.replace({ name: "Login" });
//   }
// }
  

export default {
  namespaced: true,
  strict,
  state,
  getters,
  actions,
  mutations,
};