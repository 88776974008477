import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import router from "./router/index";

import error from "./store/error";
import confirm from "./store/confirm";
import success from "./store/success";
import loader from "./store/loader";
import auth from "./store/auth";
import project from "./store/project";
import wizard from "./store/wizard";
import audience from "./store/audience";
import stimulus from "./store/stimulus";
import question from "./store/question";
import checkout from "./store/checkout";
import billing from "./store/billing";
import analysis from "./store/analysis";
import analysisV2 from "./store/analysisV2"
import settings from "./store/settings";
import multiuser from "./store/multiuser";
import folder from "./store/folder"
import subscription from "./store/Subscription"

Vue.use(Vuex);
// const debug = process.env.NODE_ENV !== "production";
// Vue.config.devtools = true;

const storeModules = {
  confirm,
  error,
  success,
  loader,
  auth,
  project,
  wizard,
  audience,
  stimulus,
  question,
  checkout,
  billing,
  analysis,
  settings,
  multiuser,
  analysisV2,
  folder,
  subscription
};

const storeModuleKeysList = Object.keys(storeModules);

/****
 * Setup persistence
 * - used 'localStorage' in order to pesist the store,
 * - persist only the 'storeModulePersistList' modules,
 * - state reducer. reduces state to only those values you want to save.
 *   For example, save entire 'analysis' module, except 'videoData'(=== null).
 */

const vuexSecure = new VuexPersistence({
  storage: window.localStorage,
  modules: storeModuleKeysList,
  reducer: state => {
    const persistStoreModules = storeModuleKeysList
      .filter(key => key !== "analysis" && key!=='analysisV2')
      .reduce((acc, cur) => {
        if (cur) acc[cur] = state[cur];
        return acc;
      }, {});
    return {
      ...persistStoreModules,
      project: { ...state.project, projectList: null }
    };
  }
});

const actions = {
  clearAll({ commit }) {
    Object.keys(storeModules)
      .filter(storeName => storeName !== "loader")
      .forEach(s => {
        commit(`${s}/RESET`);
      });
    window.sessionStorage.clear();
    window.localStorage.clear();
  },
  async logout({ commit }) {
    commit("auth/SET_IS_LOGGED_IN", false);
    router.replace({ name: "Login" });
  }
};

export default new Vuex.Store({
  modules: storeModules,
  strict: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
  plugins: [vuexSecure.plugin]
});
