import router from "../router/index";
import services from "../services";
// import i18n from "../i18n";

const strict = true;
const AUTHORIZATION_ERROR_STATUS = 401;


const defaultState = () => {
    return {
        guestMode: false,
        // return array, easy to compare
        analysisArray: [
            {
                projectId: "",
                projectAssets: [], // videos / images
                fogmap_path: "",
                gazeplot_path: "",
                heatmap_path: "",
                resource_path: "",
                saliency_scores: {
                    attention_score: 0,
                    focus_score: 0,
                    spread_score: 0,
                    clarity_score: 0,
                    mental_demand: 0,
                    video_data: [], // Array of saliency_scores + timestamp
                },
                emotion_data: {
                    amusement: 0.0,
                    anger: 0.0,
                    awe: 0.0,
                    contentment: 0.0,
                    disgust: 0.0,
                    excitement: 0.0,
                    fear: 0.0,
                    sadness: 0.0,
                    valence: 0.0,
                    engagement: 0.0,
                    video_data: [], // Array of emotion_data + timestamp
                    valence_kpi: 0,
                    engagement_kpi: 0,
                    emotion_score_kpi: 0,
                },
                data_type: "", // video / image
                file_name: "",
                benchmark: {
                    lv1: "",
                    lv2: null,
                    timestamp: "",
                    valence: 0,
                    engagement: 0,
                    emotion_score: 0,
                    focus_score: 0,
                    spread_score: 0,
                    mental_demand: 0,
                    clarity_score: 0,
                    attention_score: 0,
                    emotion_samples: 0,
                    saliency_samples: 0,
                },
                norms: {
                    globalPerformance: 0,
                    attention: 0,
                    attentionSpan: 0,
                    engagement: 0,
                    effectiveness: 0,
                    digitalPerformance: 0,
                },
            },
        ],
    };
};
const state = defaultState();

const getters = {
    isGuestMode: state => state.guestMode,
    getAnalysisArray: state => state.analysisArray,
    getProjectDataType:state=> state.data_type
}

const mutations = {
    RESET: state => {
        // state = defaultState();
        const s = defaultState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    SET_MAIN_ANALYSIS: (state, analysis) => {
        state.analysisArray[0] = analysis;
    },
    SET_COMPARISSON_ANALYSIS: (state, analysis) => {
        if (state.analysisArray.length < 2)
            state.analysisArray.push(analysis);
        else
            state.analysisArray[1] = analysis;
    },
    SET_GUEST_MODE: (state, guestMode) => (state.guestMode = guestMode),

    REMOVE_PROJECT_BY_ID:(state,projectId)=>{
        state.analysisArray = state.analysisArray.filter(item=>item.id!==projectId)
    }
}

const actions = {
    async retrieveAnalysisData({ commit, rootGetters }, payload) {
        try {
            const clientId = rootGetters["auth/getClientId"];
            const projectId = payload.projectId;
            const isComparisson = payload.isComparisson;
            
            const project = await services.ProjectService.syncAndRetrieveProjectByClientId(
                clientId,
                projectId
            );

            const analysis = project.analysis

            const norms = await services.AnalysisService.retrieveNorms();


            const projectAssets = await services.ProjectService.retrieveProjectAssets(projectId);

            analysis.projectAssets = [projectAssets];
            analysis.projectId = projectId;
            analysis.norms = norms;

            if (isComparisson)
                commit("SET_COMPARISSON_ANALYSIS", analysis);
            else
                commit("SET_MAIN_ANALYSIS", analysis);
            return {project,projectAssets,norms}
        } catch (error) {
            console.log("Error while retrieving analysisData: ", error);
            const isAuthorizationError =
                error.status === AUTHORIZATION_ERROR_STATUS;
            checkRedirectionByStatus(error.status, commit);
            commit(
                "error/SET_ERROR",
                { status: error.status },
                {
                    root: true,
                }
            );
            if (isAuthorizationError)
                return Promise.reject(isAuthorizationError);
            return Promise.reject(error);
        }

    },
}

// utils
function checkRedirectionByStatus(status, commit) {
    if (status === AUTHORIZATION_ERROR_STATUS) {
      commit("auth/SET_IS_LOGGED_IN", false, { root: true });
      router.replace({ name: "Login" });
    }
  }

export default {
    namespaced: true,
    strict,
    state,
    getters,
    actions,
    mutations,
};